<script>
import axios from "axios";
import Swal from "sweetalert2";

import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  // components: {
  //   Layout,
  // },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL_VERSION,
      configuration: [],
      url: process.env.VUE_APP_URL,
    };
  },
  mounted() {
    let self = this;

    if (localStorage.getItem("session")) {
      self.$router.push({ name: "default" });
    }

    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/konfig"
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          let clear_data_config = [];
          $.each(response_data.list_data, function (indexInArray, valueOfElement) {
            clear_data_config['LOGO'] = valueOfElement[0].value;
            clear_data_config['LOGIN_BACKGROUND'] = valueOfElement[1].value;
            clear_data_config['TITLE_APPLICATION'] = valueOfElement[2].value;
            clear_data_config['APPLICATION'] = valueOfElement[3].value;
            clear_data_config['VERSION'] = valueOfElement[4].value;
            clear_data_config['COPYRIGHT'] = valueOfElement[5].value;
            clear_data_config['TERM_CONDITION'] = valueOfElement[6].value;
            clear_data_config['CATATAN_CV'] = valueOfElement[7].value;
          });

          self.configuration = clear_data_config;
          self.term_condition = self.configuration.TERM_CONDITION;
        }
      });

    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/departemen?status=" +
        self.status
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          self.master_departement = response_data.list_data.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.message,
          });
        }
      });

    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/jabatan?status=" +
        self.status
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          self.master_jabatan = response_data.list_data.jabatan;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.message,
          });
        }
      });

    // GET KONFIG
    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/konfig"
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          let clear_data_config = [];
          $.each(response_data.list_data, function (indexInArray, valueOfElement) {
            clear_data_config['LOGO'] = valueOfElement[0].value;
            clear_data_config['LOGIN_BACKGROUND'] = valueOfElement[1].value;
            clear_data_config['TITLE_APPLICATION'] = valueOfElement[2].value;
            clear_data_config['APPLICATION'] = valueOfElement[3].value;
            clear_data_config['VERSION'] = valueOfElement[4].value;
            clear_data_config['COPYRIGHT'] = valueOfElement[5].value;
            clear_data_config['TERM_CONDITION'] = valueOfElement[6].value;
            clear_data_config['CATATAN_CV'] = valueOfElement[7].value;
          });

          self.configuration = clear_data_config
        }
      });
  },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var FormData = require("form-data");
      var data = new FormData();
      data.append("token", this.token);
      data.append("password_new", this.password_new);
      data.append("password_new_retype", this.password_new_retype);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "api/account/storeforgotpassword",
        data: data,
      };
      axios(config).then((response) => {
        var data_login = response.data;
        // check status login
        if (data_login.code != 200) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data_login.message,
          });
        } else {
          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: data_login.message,
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */

            // return false;
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "login" });
            }
          });
        }
      });
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.bg-login {
  max-width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 30px;
}
</style>
<template>
  <div class="container bg-login" v-bind:style="{
    background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no repeat',
    backgroundPosition: 'center',
  }">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="wrap-login100 mt-3 mb-3 p-5" style="background: #fff !important">
          <b-form class="p-2" @submit.prevent="StoreData">
            <span class="login100-form-logo">
              <img :src="configuration.LOGO" width="150" />
            </span>
            <span class="login100-form-title p-b-15 p-t-15 mb-3" style="font-size:20px">
              <b>NEW PASSWORD</b>
            </span>
            <b-form-group class="mb-3" id="input-group-1" label="Password Baru" label-for="input-1">
              <b-form-input id="input-1" v-model="password_new" type="password" placeholder="Password Baru"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-3" id="input-group-1" label="Konfirmasi Password" label-for="input-1">
              <b-form-input id="input-1" v-model="password_new_retype" type="password" placeholder="Konfirmasi Password"></b-form-input>
            </b-form-group>
            <div class="mt-3 d-grid">
              <b-button type="submit" variant="primary" class="btn-block"><i class="fa fa-envelope"> </i> Ganti Password</b-button>
              <!-- <router-link to="/forgot-password" class="btn btn-block btn-primary">Log In</router-link> -->
            </div>
          </b-form>
          <br />
          <div class="text-center">
            <div class="mt-4 text-center">
              <router-link :to="{ name: 'login' }" class="text-muted">
                <i class="fa fa-arrow-left"></i> Back to login page
              </router-link>
            </div>
          </div>
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>
